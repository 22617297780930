// extracted by mini-css-extract-plugin
export var wrapper = "release-page-module--wrapper--JdKnl";
export var coverAndBandcampWrapper = "release-page-module--coverAndBandcampWrapper--SIJm9";
export var albumArt = "release-page-module--albumArt--1CNy4";
export var bandcampWrapper = "release-page-module--bandcampWrapper--6YojA";
export var playerAndHeaderWrapper = "release-page-module--playerAndHeaderWrapper--3ebNp";
export var listenHeader = "release-page-module--listenHeader--1e-ND";
export var artistName = "release-page-module--artistName--5fIAv";
export var albumTitle = "release-page-module--albumTitle--1_IKV";
export var creditsWrapper = "release-page-module--creditsWrapper--zrPIq";
export var credits = "release-page-module--credits--1LgSi";